.rightImage {
  background: url("../../Assets/loginSideImage.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

h2 {
  font-size: 30px;
  color: rgba(14, 109, 167, 1);
}

h3 {
  font-size: 20px;
  color: rgba(41, 130, 142, 1);
}

.btn_primary{
    background-color:rgba(62,199,230,1) !important;
    color: white !important;
}

.btn_primary:hover{
    background-color:rgb(46, 178, 204) !important;
    color: white !important;
}

